const kpz = {

  url: "https://users.kompetenzzentrum-siegen.digital/parse",
  applicationId: "1234567890",
  userDataClass: "OpenDashUserData",
  locationClass: "Locations",
  sharedDataClass: "OpenDashSharedData",
  keyValueDataClass: "OpenDashKeyValueData",
  dashboardClass: "OpenDashDashboards",
};
const sla = {
  url: "http://10.11.10.100:9000/parse",
  applicationId: "01234567890",
  userDataClass: "UserData",
  locationClass: "Locations",
  sharedDataClass: "SharedData",
  keyValueDataClass: "KeyValueData",
  dashboardClass: "Dashboards",
};
export default kpz;

