import "babel-polyfill";

// Styles:
import "../scss/style.scss";

// Highcharts:
import "./vendor";
import "./highcharts";
import "./leaflet";
import "./render-queue";


// open.DASH:
import instance from "opendash";

// Plugins:
import routingPlugin from "opendash/plugins/router/qs";
import eudPlugin from "opendash-plugin-eud";
import owPlugin from "opendash-plugin-openware";

// Config
import parseConfig from "./parse.config";
import openwareConfig from "./openware.config";

// Translations:
import deTranslation from "opendash/translations/de";
import enTranslation from "opendash/translations/en";

// User Adapter:
import userAdapter from "@opendash/user-adapter-parse-plus";

// Components:
import heroComponent from "./components/hero.component";

import "angular-material";
// Widgets
const widgets = [
  require("opendash-widget-highcharts-live").default,
  require("opendash-widget-iot-kpi").default,
  //require("opendash-widget-greis-table").default,
  //require("opendash-widget-greis-sps-table").default,
  require("opendash-widget-table").default,
  require("opendash-widget-highcharts-minmaxline").default,
  require("opendash-widget-highcharts-heatmap").default,
  require("opendash-widget-highcharts-histogramm").default,
  require("opendash-widget-highcharts-gauge").default,
  require("opendash-widget-highcharts-polar").default,
  require("opendash-widget-highcharts-columnrange").default,
  require("opendash-widget-highcharts-timepattern").default,

  require("opendash-widget-highcharts-linecompare").default,
  require("opendash-widget-iot-states").default,
  require("opendash-widget-iot-topstates").default,
  require("opendash-widget-iot-statesgantt").default,
  require("opendash-widget-energy-peak-table").default,
  //require("opendash-widget-energy-interval-compare").default,
  require("opendash-widget-energy-total-compare").default,

  require("opendash-widget-iot-reference-timeline").default,
  require("opendash-widget-openware-aggregation-history").default,
  require("opendash-widget-openware-aggregation-history-stacked").default,
  require("opendash-widget-leaflet-geojson").default,

];

// Core Config:
instance.env("OD-EVENTS-LOG", false);
instance.env("OD-DASHBOARDS-BY-LOCATION", true);
instance.env("OD-DATA-VALIDATION", false);

// Other Config:
instance.env("OD-ANALYTICS-SENSORS-BASEURL", "api/analytics");
instance.env("OD-ANALYTICS-OPERATIONS-BASEURL", "api/analytics");
instance.env("OD-ANALYTICS-ALARMS-BASEURL", "api/alarms");
instance.env("OD-ANALYTICS-APPID", "01234567890");

// Register Plugins
instance.use(routingPlugin);
instance.use(eudPlugin);
instance.use(owPlugin, openwareConfig);

// Translations:
instance.registerTranslation(deTranslation);
instance.registerTranslation(enTranslation);

// User Adapter
instance.registerUserAdapter(userAdapter, parseConfig);

// Register Widgets
instance.registerWidgets(widgets);

// Start instance
instance.start();

// App initialisieren:
const app = angular.module("app", [instance.name, 'ngMaterial']).config(function ($mdThemingProvider) {
  $mdThemingProvider.theme('default')
    .primaryPalette('blue-grey')
    .accentPalette('blue');
});

app.component("hero", heroComponent);

var tileProviderFactory = () => {
  return L.tileLayer(
    "https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png",
    {}
  )
  /*
  return L.tileLayer(
    "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFydGluc3RlMW4iLCJhIjoiY2owczgwZzQ3MDA3YzJxbHI2aXJqenUycCJ9.SKTsxxLPfMeqvdwjnI11Dw",
    {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: "mapbox/streets-v11",
      tileSize: 512,
      zoomOffset: -1,
      accessToken:
        "pk.eyJ1IjoibWFydGluc3RlMW4iLCJhIjoiY2owczgwZzQ3MDA3YzJxbHI2aXJqenUycCJ9.SKTsxxLPfMeqvdwjnI11Dw",
    }
  )*/
};
instance.env("OD-LEAFLET-TILE-PROVIDER", tileProviderFactory);
app.run([
  "$injector",
  ($injector) => {
    const $header = $injector.get("opendash/services/header");
    const $event = $injector.get("opendash/services/event");


    // $header.addLogo(
    //   require("/public/images/hg.jpg")
    // );

    /*
        $header.addLogo(
          "https://www.machwerk-hennef.de/wp-content/uploads/2020/01/machwerk_logo_quer-ohne-fablab.png"
        );
        */
    $header.addLogo(
      "https://www.si-automation.de/images/logo.png"
    );

    $event.on("od-dashboard-ready", () => {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    });

  },
]);


app.run([
  "$injector",
  async $injector => {
    const $data = $injector.get("opendash/services/data");
    const $dashboard = $injector.get("opendash/services/dashboard");
    const $event = $injector.get("opendash/services/event");
    /*
        $event.on("od-dashboard-ready", () => {
          try {
            if ($dashboard.current.widgets.length === 0) {
              $data.list().forEach(item => {
                item.valueTypes.forEach((vt, valueTypeIndex) => {
                  $dashboard.addWidget({
                    name: "Kennzahl",
                    type: "opendash-widget-iot-kpi",
                    config: {
                      item: [item.id, valueTypeIndex],
                      icon: vt.type === "Number" ? "fa-hashtag" : "fa-info",
                      iconside: "Links",
                      kpitype: 1,
                      kpitime: 1,
                      kpitimemethod: 1
                    }
                  });
                });
              });
            }
          } catch (error) {
            console.error(error);
          }
        }); */
  }
]);