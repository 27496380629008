import * as Highcharts from "highcharts/highstock";

require("highcharts/highcharts-more")(Highcharts);
require("highcharts-regression")(Highcharts);
require("highcharts/modules/boost")(Highcharts);
require("highcharts/modules/heatmap")(Highcharts);
require("highcharts/modules/histogram-bellcurve")(Highcharts);
require("highcharts/modules/solid-gauge")(Highcharts);
require("highcharts/modules/sankey")(Highcharts);
require("highcharts/modules/gantt")(Highcharts);

Highcharts.setOptions({
  boost: {
    useGPUTranslations: false,
    useAlpha: false,
    enabled: false,
  },
  colors: [
    "#175b98",
    "#778899",
    "#708090",
    "#D3D3D3",
    "#C0C0C0",
    "#A9A9A9",
    "#808080",
    "#696969",
  ],
  primColor: "#175b98",
  chart: {
    backgroundColor: null,
    style: {
      fontSize: "11px",
    },
  },
  global: {
    useUTC: false,
  },
  series: {
    turboThreshold: 1,
  },
  lang: {
    thousandsSep: ".",
    decimalPoint: ",",
    months: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    weekdays: [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ],
    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ],
    notData: "Keine Daten verfügbar",
    resetZoom: "Zoom zurücksetzen",
  },
});

window.Highcharts = Highcharts;
